import { Container, Image } from "react-bootstrap";
import "./styles.scss";
import { partnershipGreens } from "../../themes/colours";
const Hero = ({
  title,
  colour,
  imgUrl,
  fullWidth,
  titleColor,
  mobileCentered,
  overlayImgUrl,
  customClass,
}) => {
  const url = imgUrl
    ? imgUrl
    : `url("/assets/Hero/JLP_Ampersand_Main_RGB_White.svg")`;
  const titleColorHex = titleColor ? titleColor : partnershipGreens.green;
  return (
    <div
      className={`hero d-flex ${
        fullWidth === "true" ? "full-width-background" : ""
      } ${customClass ? customClass : ""}`}
      style={{
        backgroundColor: colour,
        backgroundImage: url,
        color: titleColorHex,
      }}
    >
      <Container
        className={`text-center ${
          mobileCentered === "true" ? "mobile-centered" : ""
        }`}
      >
        {!overlayImgUrl && <h2 style={{ color: titleColorHex }}>{title}</h2>}
        {overlayImgUrl && (
          <Image className="overlay-logo" src={overlayImgUrl} />
        )}
      </Container>
    </div>
  );
};

export default Hero;
