import { Container, Image, Col } from "react-bootstrap";
import Hero from "../../../components/Hero";
import "./styles.scss";
import Infographics from "../../../components/Infographics";
import MediaTabsComponent from "../../../components/Tabs/Media";
const JLPage = () => {
  const tabs = [
    {
      title: "Onsite Media",
      eventKey: "onsite-media",
      headline:
        "JL.com is a fast-growing channel that accounts for 61% of total sales at John Lewis. Appear on media inventory across the website and app, engaging customers with relevant, highly visible content to raise awareness and drive sales for your brand.",
      buttonText: "Find out more",
      imageLink: '/assets/photography/2x2023_BM_JLP_JohnLewis_Shot_14_49.jpg',
      downloadLink: 'https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/JL/ONSITE+-+John+Lewis+Insights+%26+Media+-+JL+Media+Kit+2023.pdf',
      points: [
        {
          pointTitle: "With onsite media you can:",
          text: "Boost brand awareness with highly visible placements on the homepage/nDrive conversions through category pages",
        },
        {
          pointTitle: "Key channel stats / Audience",
          text: "500 million visits annually/nAverage online order value: £150",
        },
        {
          pointTitle: "Media",
          text: "Display opportunities across key touchpoints and devices (desktop, mobile and app).",
        },
      ],
    },
    {
      title: "Offsite: Digital & Social",
      eventKey: "offsite",
      headline:
        "Extend the reach of your eComm campaigns by targeting shoppers with a network of partner media across programmatic, social and affiliate channels.",
      buttonText: "Find out more",
      imageLink: '/assets/photography/2x2023_BM_RetailMedia_JohnLewis_Shot_4_128.jpg',
      downloadLink: 'https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/JL/DIGITAL+%26+SOCIAL+-+John+Lewis+Insights+%26+Media+-+JL+Media+Kit+2023.pdf',
      points: [
        {
          pointTitle: "With offsite media you can:",
          text: "Drive reach amongst hard to find customers across the web with a brand message or conversion call to action/nPair with online media to reinforce your brand message and maximise conversions/nEnhance your campaigns through a cross channel activation strategy.",
        },
        {
          pointTitle: "Key channel and audience stats",
          text: "Affiliates – drives on average 18 million visits a year/nSocial – over 1 million followers on IG and over 1.4 million on Facebook/nProgrammatic monthly reach of 25m impressions",
        },
        {
          pointTitle: "Media",
          text: "Social/nAffiliates/nPPC/nProgrammatic Display/nRatings & Reviews",
        },
      ],
    },
    {
      title: "Instore",
      eventKey: "instore",
      headline:
        "Influence and inspire customers during their shopping journey with high impact displays.",
      buttonText: "Find out more",
      imageLink: '/assets/photography/2x2023_BM_RetailMedia_JohnLewis_Shot_2_13.jpg',
      downloadLink: 'https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/JL/STORE+MEDIA+-+John+Lewis+Insights+%26+Media+-+JL+Media+Kit+2023.pdf',
      points: [
        {
          pointTitle: "With in-store media you can:",
          text: "Boost brand engagement and buzz through direct to customer activity/nBuild trust and brand equity amongst JL’s most loyal shoppers/nDrive conversion with couponing and price promos.",
        },
        {
          pointTitle: "Key channel and audience stats",
          text: "Over 56 million visits a year to our store network nationwide/nAverage 137k visits per store per month",
        },
        {
          pointTitle: "Media",
          text: "Windows/nExperiential",
        },
      ],
    },
    {
      title: "CRM & Loyalty",
      eventKey: "crm-loyalty",
      headline:
        "With more than 5.5m members, we have one of the largest and most engaged loyalty programmes in the UK.  Boost your brand awareness and sales by engaging with these shoppers across a range of innovative direct-to-customer initiatives.",
      buttonText: "Find out more",
      imageLink: '/assets/photography/2x2023_BM_RetailMedia_JohnLewis_Shot_10_206.jpg',
      downloadLink: 'https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/JL/CRM+%26+LOYALTY+-+John+Lewis+Insights+%26+Media+-+JL+Media+Kit+2023.pdf',
      points: [
        {
          pointTitle: "With CRM & Loyalty you can:",
          text: "Increase brand engagement and buzz through direct to customer activity/nBuild trust and brand equity amongst JL’s most loyal shoppers/nDrive conversion with couponing and price promo.",
        },
        {
          pointTitle: "Key channel & audience stats",
          text: "CRM customers visit 5 times a year spending over £550/nLoyalty customers visit 7 times a year, spending nearly £800 a year on average",
        },
        {
          pointTitle: "Media",
          text: "Email/nIncentives/nMonthly Competitions/nFirst to Buy",
        },
      ],
    },
  ];

  const infographics = [
    {
      imgUrl:
        "/assets/infographics/JLP_Icon_RGB_Orange_Supplier Funded Incentives.svg",
      textHeadline: "We are",
      text: "1st in terms of Customer Service & NPS",
    },
    {
      imgUrl: "/assets/infographics/JLP_Icon_RGB_Orange_Loyalty.svg",
      textHeadline: "We attract",
      text: "12M customers per year, with 5.5M loyalty card holders",
    },
    {
      imgUrl: "/assets/infographics/JLP_Icon_RGB_Orange_Instore_Windows.svg",
      textHeadline: "We’re proud to have",
      text: "34 stores nationwide that attract over 56 million visitors a year",
    },
    {
      imgUrl: "/assets/infographics/JLP_Icon_RGB_Orange_Media.svg",
      textHeadline: "We receive",
      text: "500 million site visits annually",
    },
  ];
  return (
    <div className="media-container">
      <Hero
        title="JOHN LEWIS"
        fullWidth="true"
        titleColor="#ffffff"
        mobileCentered="true"
        overlayImgUrl="/assets/logos/JL_overlay.png"
        customClass="john-lewis"
      />

      <Container className="w-100 main-container customers">
        <h3 className="text-md-center">
          Build connections that matter with John Lewis
        </h3>
        <p className="body-text body-l text-md-center mb-4">
          Reach your ideal customer through John Lewis’ extensive physical and
          digital networks, as well as our loyal customer base.
        </p>
        <p className="body-text body-l text-md-center mb-4">
          A few facts and figures about John Lewis:
        </p>
        <Infographics infographics={infographics} />
      </Container>
      <div className="block">
        <Container className="w-100 main-container">
          <h3 className="text-md-center">The places you’ll want to be seen</h3>
          <p className="body-text body-l text-md-center">
            Using our portfolio of premium, high-visibility media you can
            activate across the entire customer journey. So you meet your brand
            objectives at every touchpoint.
          </p>
        </Container>
      </div>

      <div className="block-container">
        <div className="left-triangle"></div>
        <div className="right-triangle"></div>
        <div className="arrow"></div>
        <div className="block block-1">
          <Container>
            <Col xs={6}>
              <h3>Awareness</h3>
            </Col>
            <Col xs={6}>
              <ul>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Affiliates.svg"
                    />
                  </span>
                  <p className="body-l">Affiliates</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_PPC.svg"
                    />
                  </span>
                  <p className="body-l">PPC</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Offsite_Media.svg"
                    />
                  </span>
                  <p className="body-l">Offsite programmatic display</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Social.svg"
                    />
                  </span>
                  <p className="body-l">Social</p>
                </li>
              </ul>
            </Col>
          </Container>
        </div>
        <div className="block block-2">
          <Container>
            <Col xs={6}>
              <h3>Consideration</h3>
            </Col>
            <Col xs={6}>
              <ul>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_CRM.svg"
                    />
                  </span>
                  <p className="body-l">CRM email</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Competitions.svg"
                    />
                  </span>
                  <p className="body-l">Monthly competitions</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Coupons on App.svg"
                    />
                  </span>
                  <p className="body-l">Personalised offers</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_First_to_Buy.svg"
                    />
                  </span>
                  <p className="body-l">First to buy</p>
                </li>
              </ul>
            </Col>
          </Container>
        </div>
        <div className="block block-3">
          <Container>
            <Col xs={6}>
              <h3>Conversion</h3>
            </Col>
            <Col xs={6}>
              <ul>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Onsite_Media.svg"
                    />
                  </span>
                  <p className="body-l">Onsite media</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Instore_Windows.svg"
                    />
                  </span>
                  <p className="body-l">Window</p>
                </li>
              </ul>
            </Col>
          </Container>
        </div>
      </div>
      <div className="form-background">
        <Container className="w-100 main-container tab-container">
          <h3 className="text-md-center">Opportunities too good to pass up</h3>
          <p className="body-text body-l mb-4 text-md-center">
            Take a closer look at our diverse range of prominent media
            opportunities to help you meet your objectives through the funnel.
          </p>

          <MediaTabsComponent tabs={tabs} />
        </Container>
      </div>
    </div>
  );
};

export default JLPage;
