import { Tabs, Tab, Col, Row, Image } from "react-bootstrap";
import DownloadButton from "../../Buttons/Download";
import "./styles.scss";

const MediaTabsComponent = ({ tabs }) => {
  return (
    <Tabs fill className="insights-tabs">
      {tabs.map(({ title, eventKey, headline, points, downloadLink, imageLink }) => {
        return (
          <Tab title={title} eventKey={eventKey}>
            <Row>
              <Col lg={8}>
                <p className="body-l text-start">{headline}</p>
                {points.map(({ pointTitle, text }) => {
                  const textArr = text.split("/n");
                  return (
                    <div className="tab-point">
                      <p className="small-print-headline">{pointTitle}</p>
                      <ul className="small-print-body styled-list">
                        {textArr.map((value) => {
                          return <li>{value}</li>;
                        })}
                      </ul>
                    </div>
                  );
                })}
                <DownloadButton variant="primary" text="Find out more" downloadLink={downloadLink} />
              </Col>
              <Col lg={4}>
                <Image src={imageLink} />
              </Col>
            </Row>
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default MediaTabsComponent;
