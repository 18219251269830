import { Button } from "react-bootstrap";

const DownloadButton = ({ variant, text, downloadLink }) => {
  return (
    <Button className="mb-4" variant={variant}>
      <a href={downloadLink} target="_blank" className="button-link">{text}</a>
    </Button>
  );
};

export default DownloadButton;
