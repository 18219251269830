import Hero from "../../components/Hero";
import { partnershipGreens } from "../../themes/colours";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.scss";
import CardComponent from "../../components/Card";
const MediaPage = () => {
  const cards = [
    {
      imgUrl: "/assets/photography/media_left.png",
      text: "Find out more",
      link: "/media/john-lewis",
      logoUrl: "/assets/logos/JL_overlay.png",
    },
    {
      imgUrl: "/assets/photography/media_right.png",
      text: "Find out more",
      link: "/media/waitrose",
      logoUrl: "/assets/logos/waitrose_overlay.png",
    },
  ];
  return (
    <div className="media-container">
      <Hero
        colour={partnershipGreens.lightGreen}
        title=" Join us on our journey"
      />
      <Container className="w-100 main-container">
        <p className="body-l body-text mb-4">Connected. Seamless. Effective.</p>
        <p className="body-l body-text mb-4">
          Reach your customers at home, on the move or in-store when you partner
          with John Lewis and Waitrose across our premium retail estates.
        </p>
        <p className="body-l body-text mb-4">
          We use insights to inform our media planning process, and data to
          improve our targeting and measurement across channels. We have new
          inventory and touchpoints right across the shopper journey. And
          exciting new partnerships with industry leaders like dunnhumby, Citrus
          and Salesforce to help us – and you – accelerate.
        </p>
        <p className="body-l body-text mb-4">
          Our capabilities are constantly evolving, helping you to reach the
          right customers at the right moments.
        </p>
      </Container>
      <div className="form-background">
        <Container className="w-100 main-container">
          <h3 className="text-md-center">Discover our opportunities</h3>

          <Row>
            {cards.map((card) => {
              return (
                <Col xs={12} lg={6} style={{ marginBottom: "1.5rem" }}>
                  <CardComponent card={card} />
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
      <div>
        <Container className="w-100 main-container pb-0">
          <h3 className="text-md-center">Join us on our journey</h3>
          <p className="body-l text-md-center">
            Here’s a snapshot of our plans and what you can expect to see over
            the coming year (just to note, timings are estimates and may
            change).
          </p>
        </Container>
        <Row className="coloured-blocks">
          <Col xs={12} lg={6}>
            <div className="block">
              <div>
                <h3>Now</h3>
                <p className="body-l">6 MONTHS</p>
              </div>
              <div>
                <h4>Waitrose:</h4>
                <p className="body-l">
                  Personalised Offers POC
                  <ul className="mt-3">
                    <li>Citrus:</li>
                    <li>Display banners</li>
                    <li>Sponsored Products on Favourites & PDPs page</li>
                  </ul>
                </p>
              </div>
              <div>
                <h4>John Lewis:</h4>
                <p className="body-l">
                  <ul>
                    <li>Personalised Offers POC</li>
                    <li>1PD POC for Offsite/Social Media</li>
                    <li>Solus CRM</li>
                  </ul>
                  <ul className="mt-3">
                    <li>Citrus:</li>
                    <li>Sponsored Products in Search</li>
                    <li>Display Banners in App</li>
                  </ul>
                </p>
              </div>
            </div>
          </Col>
          <Col
            xs={12}
            lg={6}
            className="mt-2 mb-2 mt-lg-0 mb-lg-0 ms-lg-3 me-lg-3"
          >
            <div className="block">
              <div>
                <h3>Next</h3>
                <p className="body-l">6 - 12 MONTHS</p>
              </div>
              <div>
                <h4>Waitrose:</h4>
                <p className="body-l">
                  <ul>
                    <li>Personalised Offers full capability launch</li>
                    <li>Insights to support Media planning process trial</li>
                    <li>1PD POC for Offsite/Social Media</li>
                  </ul>
                </p>
              </div>
              <div>
                <h4>John Lewis:</h4>
                <p className="body-l">
                  <ul>
                    <li>Insights to support Media planning process trial</li>
                    <li>Personalised Offers full capability launch</li>
                  </ul>

                  <ul className="mt-3">
                    <ul>
                      <li>Citrus:</li>
                      <li>Sponsored Products in browse</li>
                      <li>Sponsored Search Display Banner</li>
                      <li>Display Banners at checkout</li>
                    </ul>
                  </ul>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MediaPage;
