import { Row, Col, Image } from "react-bootstrap";

const TextBlock = ({ blocks }) => {
  return blocks.map(({ imageUrl, title, text }) => {
    return (
      <Row className="creative-block g-0">
        <Col lg={6}>
          <Image src={imageUrl} />
        </Col>
        <Col lg={6} className="text-block">
          <h3>{title}</h3>
          <p className="body-l">{text}</p>
        </Col>
      </Row>
    );
  });
};

export default TextBlock;
