import * as React from "react";
const SvgChevronRightSm = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path fill="currentColor" d="M6.94 3h2.12l9 9-9.001 9H6.938l9-9L6.94 3Z" />
  </svg>
);
export default SvgChevronRightSm;
