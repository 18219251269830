import { Image, Col, Row } from "react-bootstrap";

const Infographics = ({ infographics }) => {
  return (
    <Row className="info-row">
      {infographics.map(({ imgUrl, textHeadline, text }) => {
        return (
          <Col lg={3}>
            <div className="info-container">
              <div className="img-container">
                <Image height={85} width={85} src={imgUrl} />
              </div>
              <p className="body-l text-center">{textHeadline}</p>
              <p className="body-l text-center">{text}</p>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default Infographics;
