import { Container } from "react-bootstrap";
import "./styles.scss";
import Hero from "../../components/Hero";
import { partnershipGreens } from "../../themes/colours";
import ContactForm from "../../components/ContactForm";

const Contact = () => {
  return (
    <>
      <Hero colour={partnershipGreens.lightGreen} title="Contact us" />
      <Container className="w-100 main-container">
        <p className="body-l body-text mb-4">
        Our dedicated team is on hand to help you with any questions.
        </p>
        <p className="body-l body-text">

Not sure who to reach out to? Use the form and we'll come back to you. 

        </p>
        <ContactForm />
      </Container>
    </>
  );
};

export default Contact;
