import Hero from "../../components/Hero";
import { happyOranges } from "../../themes/colours";
import { Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import "./styles.scss";
const MeetTheTeamPage = () => {
  const tabs = [
    {
      title: "Insights & Media - John Lewis",
      eventKey: "johnLewis",
      teamMembers: [
        {
          name: "Anna Khan",
          jobTitle: "Media Sales Lead",
          category: "",
          email: "anna.khan@johnlewis.co.uk",
        },
        {
          name: "Louise Elliott",
          jobTitle: "Senior Media Sales Manager",
          category: ", Fashion",
          email: "louise.m.elliott@johnlewis.co.uk",
        },
        {
          name: "Kathryn Staples",
          jobTitle: "Media Sales Manager",
          category: ", Nursery and Kids",
          email: "kathryn.staples@johnlewis.co.uk",
        },
        {
          name: "Francesco Covelluzzi",
          jobTitle: "Media Sales Specialist",
          category: ", Fashion",
          email: "francesco.covelluzzi@johnlewis.co.uk",
        },
        {
          name: "Suzanna Knight",
          jobTitle: "Senior Media Sales Manager",
          category: ", Home",
          email: "suzanna.knight@johnlewis.co.uk",
        },
        {
          name: "Abbie Rigg-Milner",
          jobTitle: "Media Sales Specialist",
          category: ", Home",
          email: "abbie.rigg-milner@johnlewis.co.uk",
        },
        {
          name: "Frankie Ünlü",
          jobTitle: "Senior Media Sales Manager",
          category: ", Home",
          email: "frankie.unlu@johnlewis.co.uk",
        },
        {
          name: "Keira Hall",
          jobTitle: "Media Sales Manager",
          category: ", Beauty",
          email: "keira.hall@johnlewis.co.uk",
        },
        {
          name: "Shannon Davern",
          jobTitle: "Media Sales Manager",
          category: ", Beauty",
          email: "shannon.davern@johnlewis.co.uk",
        },

        {
          name: "Jiashing Moore",
          jobTitle: "Media Sales Specialist",
          category: ", Beauty",
          email: "jiashing.moore@johnlewis.co.uk",
        },
        {
          name: "Jason King",
          jobTitle: "Senior Media Sales Manager (Electricals)",
          category: ", Tech",
          email: "jason.king@johnlewis.co.uk",
        },
        {
          name: "Manoj Jadeja",
          jobTitle: "Senior Media Sales Manager (TV and Audio)",
          category: ", Tech",
          email: "manoj.jadeja@johnlewis.co.uk",
        },
        {
          name: "Sophie McDermott",
          jobTitle: "Senior Media Sales Manager (Computing and Android)",
          category: ", Tech",
          email: "sophie.mcdermott@johnlewis.co.uk",
        },
        {
          name: "Ryan Warrington",
          jobTitle: "Media Sales Specialist",
          category: ", Tech",
          email: "ryan.warrington@johnlewis.co.uk",
        },
      ],
    },
    {
      title: "Insights & Media - Waitrose",
      eventKey: "waitrose",
      teamMembers: [
        {
          name: "Nicky Manners",
          jobTitle: "Media Sales Lead",
          category: "",
          email: "nicola.manners@waitrose.co.uk ",
        },
        {
          name: "Adam Shinegold",
          jobTitle: "Media Sales Manager",

          category:
            "Beer & Cider, Soft Drinks, Fruit, Vegetables & Horti (FVH)",
          email: "adam.shinegold@waitrose.co.uk ",
        },
        {
          name: "Angela Jones",
          jobTitle: "Media Sales Manager",
          category: "Dairy, General Merchandise",
          email: "angela.jones@waitrose.co.uk",
        },
        {
          name: "Katie Kimberley",
          jobTitle: "Media Sales Manager",
          category: "Health & Beauty, Core Grocery",
          email: "katie.kimberley@waitrose.co.uk",
        },
        {
          name: "Naomi Cheung",
          jobTitle: "Media Sales Manager",
          category:
            "Meat, Fish, Poultry & Eggs, Household, Frozen, Confectionery, Pet",
          email: "naomi.cheung@waitrose.co.uk",
        },
        {
          name: "Rachel Crowe",
          jobTitle: "Media Sales Manager",
          category: "Spirits, Wine, Bakery",
          email: "rachel.crowe@waitrose.co.uk",
        },
        {
          name: "Natasha (Tash) Leyens",
          jobTitle: "Media Sales Manager",
          category: "Third Party",
          email: "natasha.x.leyens@waitrose.co.uk",
        },
        {
          name: "Katy Lawton",
          jobTitle: "Media Sales Manager",
          category:
            "Chilled Prepared Food, Grocery Meal Solutions, Deli, Snacks",
          email: "katy.lawton@waitrose.co.uk",
        },
        {
          name: "Charlotte Morris",
          jobTitle: "Business Support Executive",
          category:
            "Waitrose.com - Angela & Rachel,  WaitroseCellar.com, Waitrose Food, Recipe Cards",
          email: "charlotte.x.morris@waitrose.co.uk",
        },
        {
          name: "Annabel Gosling",
          jobTitle: "Business Support Executive",
          category:
            "Waitrose.com - Katy & Adam, Social & Digital, JCDecaux, Dish Podcast",
          email: "annabel.gosling@johnlewis.co.uk",
        },
        {
          name: "Jordan South",
          jobTitle: "Business Support Executive",
          category:
            "Waitrose.com - Naomi & Natasha, Waitrose Weekend, Sampling",
          email: "jordan.south@johnlewis.co.uk",
        },
        {
          name: "Kirsty Girard",
          jobTitle: "Business Support Executive",
          category:
            "Waitrose.com - Katie, POS, Coupon at Till incl myWaitrose",
          email: "kirsty.girard@waitrose.co.uk",
        },
        {
          name: "Kelsey Timpson",
          jobTitle: "Business Support Executive",
          category: "On Maternity Leave",
          email: "kelsey.timpson@waitrose.co.uk",
        },
      ],
    },
  ];
  return (
    <div className="team-page">
      <Hero colour={happyOranges.orange} title="Getting results, together" />
      <Container className="w-100 main-container">
        <p className="body-l body-text">
          Our dedicated team is on hand to help you with any query you might
          have. Please see below to find out who you should be talking to.
        </p>
        <Tabs fill>
          {tabs.map(({ teamMembers, title, eventKey }) => {
            return (
              <Tab title={title} eventKey={eventKey}>
                <Row className="team-row">
                  {teamMembers.map(({ name, jobTitle, category, email }) => {
                    return (
                      <Col lg={6} className="mb-4">
                        <p style={{ fontWeight: 700 }} className="body-l">
                          {name}
                        </p>
                        {eventKey === "johnLewis" && (
                          <p className="body-l">
                            {jobTitle}
                            {category}
                          </p>
                        )}
                        {eventKey === "waitrose" && (
                          <>
                            <p className="body-l">{jobTitle}</p>
                            <p className="body-l">{category}</p>
                          </>
                        )}
                        <p className="body-l">{email}</p>
                      </Col>
                    );
                  })}
                </Row>
              </Tab>
            );
          })}
        </Tabs>
      </Container>
    </div>
  );
};

export default MeetTheTeamPage;
