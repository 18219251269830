import React from "react";
import Container from "react-bootstrap/Container";
import "./styles.scss";
import { Accordion, Col, Row } from "react-bootstrap";
const pages = [
  {
    name: "JLP Insights & Media",
    subPages: [
      {
        name: "Media",
        link: "/media",
      },
      {
        name: "Creative",
        link: "/creative",
      },
      {
        name: "Insights",
        link: "/insights",
      },
      {
        name: "Meet the team",
        link: "/meet-the-team",
      },
      {
        name: "Contact us",
        link: "/contact",
      },
    ],
  },
  {
    name: "Useful information",
    subPages: [
      {
        name: "Privacy policy",
        link: "https://www.johnlewispartnership.co.uk/meta/privacy-policy.html",
      },
      {
        name: "Legal",
        link: "https://www.johnlewispartnership.co.uk/meta/legal.html",
      },
      {
        name: "Accessibility",
        link: "https://www.johnlewispartnership.co.uk/meta/accessibility.html",
      },
      {
        name: "Website Cookies",
        link: "https://www.johnlewispartnership.co.uk/meta/cookies.html",
      }
    ],
  },
  {
    name: "Other websites",
    subPages: [
      {
        name: "John Lewis & Partners",
        link: "https://www.johnlewis.com/",
      },
      {
        name: "Waitrose & Partners",
        link: "https://www.waitrose.com/",
      },
      {
        name: "John Lewis Partnership Jobs",
        link: "https://www.jlpjobs.com/",
      },
    ],
  },
];

function FooterComponent() {
  return (
    <footer className="coloured-footer">
      <Container className="w-100">
        <Row className="w-100">
          {pages.map((page, index) => {
            const {subPages} = page;
            return (
              <Col lg={4}>
                <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen>
                  <Accordion.Item eventKey={`${index}`}>
                    <Accordion.Header className="footer-list-title">
                      <span className="body-l">{page.name}</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        {subPages.map((subpage) => {
                          const {name, link} = subpage;
                          return (
                            <li key={name} className="body-m">
                              <a href={`${link}`}>{name}</a>
                            </li>
                          );
                        })}
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            );
          })}
        </Row>
      </Container>
    </footer>
  );
}

export default FooterComponent;
