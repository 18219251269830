import { Form } from "react-bootstrap";
import "./styles.scss";
const InputField = ({ value, label, type, onChange }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <div>
      {label && <Form.Label>{label}</Form.Label>}

      {type === "textarea" ? (
        <Form.Control as="textarea" rows={4} onChange={handleChange} />
      ) : (
        <Form.Control type={type} value={value} onChange={handleChange} />
      )}
    </div>
  );
};

export default InputField;
