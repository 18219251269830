import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useLocation } from "react-router-dom";
import "./styles.scss";
import { Col, Image, Row } from "react-bootstrap";
const pages = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Media",
    link: "/media",
  },
  {
    name: "Creative",
    link: "/creative",
  },
  {
    name: "Insights",
    link: "/insights",
  },
  {
    name: "Meet the team",
    link: "/meet-the-team",
  },
  {
    name: "Contact us",
    link: "/contact",
  },
];

function NavbarComponent() {
  const location = useLocation();
  const activeKey = `/${location.pathname.split("/")[1]}`;
  return (
    <Navbar
      collapseOnSelect
      variant="dark"
      expand="lg"
      className="coloured-nav "
    >
      <Container className="w-100">
        <Row className="w-100 g-0">
          <Col className="d-flex justify-content-center justify-content-lg-start justify-content-xl-start">
            <a href="/" className="logo">
              <Image
                className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
                src="/assets/logos/JLP_Insights_and_Media_RGB_Orange 1.png"
              />
              <Image
                className="d-lg-none d-xl-block d-xl-none"
                src="/assets/logos/JLP_Insights_and_Media_RGB_Orange.svg"
              />
            </a>
          </Col>
        </Row>
      </Container>
      <Row className="nav-row w-100 justify-content-center">
        <Col lg={6} className="d-flex flex-column align-items-end">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse className="w-100" id="responsive-navbar-nav">
            <Nav activeKey={activeKey} className="w-100">
              {pages.map((page) => {
                const {name, link} = page;
                return (
                  <Nav.Link key={name} className="flex-grow-1" href={`${link}`}>
                    <span>{`${name}`}</span>
                  </Nav.Link>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Col>
      </Row>
      {/* </Container> */}
    </Navbar>
  );
}

export default NavbarComponent;
