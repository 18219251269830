import Hero from "../../components/Hero";
import { happyOranges } from "../../themes/colours";
import { Col, Container, Row, Image } from "react-bootstrap";
import "./styles.scss";
const CreativePage = () => {
  return (
    <>
      <Hero
        colour={happyOranges.orange}
        title="Harness the power of John Lewis creative"
      />
      <Container className="w-100 main-container ">
        <p className="body-l body-text mb-4">
          Our fully serviced creative studio can help you craft engaging, high
          impact assets for your campaign. Capturing customer attention, getting
          deeper engagement and driving better campaign performance.
        </p>
        <p className="body-l body-text mb-4">
          At the moment we’re able to offer our creative capabilities for John
          Lewis only.
        </p>

        <p className="body-l body-text">
          Get in touch if you’d like more information on working with us.
        </p>
      </Container>
      <Container className="w-100 main-container pt-0">
        <Row className="creative-block">
          <Col xs={12} lg={4}>
            <Image src="/assets/photography/creative_child.png" />
          </Col>
          <Col className="mt-2 mb-2 mt-lg-0 mb-lg-0" xs={12} lg={4}>
            <Image src="/assets/photography/creative_serum.png" />
          </Col>
          <Col xs={12} lg={4}>
            <Image src="/assets/photography/creative_juice.png" />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreativePage;
