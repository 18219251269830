import { Card, Image } from "react-bootstrap";
import { white } from "../../themes/colours";
import ChevronRightSm from "../../assets/icons/chevrons/right/ChevronRightSm";
import "./styles.scss";
const CardComponent = ({ card }) => {
  const { title, imgUrl, text, link, logoUrl } = card;
  return (
    <a href={link}>
      <Card>
        <Card.Img src={imgUrl} />
        <Card.ImgOverlay>
          {title && <Card.Title className="heading-m">{title}</Card.Title>}
          {logoUrl && <Image src={logoUrl} />}
          {text && (
            <Card.Text>
              {text} <ChevronRightSm color={white} />
            </Card.Text>
          )}
        </Card.ImgOverlay>
      </Card>
    </a>
  );
};

export default CardComponent;
