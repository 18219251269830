import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavbarComponent from "./components/Navbar";
import FooterComponent from "./components/Footer";
import Contact from "./pages/Contact";
import CreativePage from "./pages/Creative";
import InsightsPage from "./pages/Insights";
import MediaPage from "./pages/Media";
import Homepage from "./pages/Home";
import WaitrosePage from "./pages/Media/Waitrose";
import JLPage from "./pages/Media/JL";
import MeetTheTeamPage from "./pages/Team";

function App() {
  return (
    <>
      <Router>
        <NavbarComponent />
        <Routes>
          <Route path="/" exact element={<Homepage />} />
          <Route path="/media" exact element={<MediaPage />} />
          <Route path="/media/waitrose" exact element={<WaitrosePage />} />
          <Route path="/media/john-lewis" exact element={<JLPage />} />
          <Route path="/creative" exact element={<CreativePage />} />
          <Route path="/insights" exact element={<InsightsPage />} />
          <Route path="/meet-the-team" exact element={<MeetTheTeamPage />} />
          <Route path="/contact" exact element={<Contact />} />
        </Routes>
        <FooterComponent />
      </Router>
    </>
  );
}

export default App;
