import "./styles.scss";

const RadioButton = ({ value, label, onChange, isSelected, id, name }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <div className="radio">
      <input
        value={value}
        onChange={handleChange}
        name={name}
        type="radio"
        checked={isSelected}
        id={id}
      />
      <label htmlFor={id} className="radio-label">
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
