import { Container, Image, Col } from "react-bootstrap";
import Hero from "../../../components/Hero";
import "./styles.scss";
import MediaTabsComponent from "../../../components/Tabs/Media";
import Infographics from "../../../components/Infographics";
const WaitrosePage = () => {
  const tabs = [
    {
      title: "Onsite Media",
      eventKey: "onsite-media",
      headline:
        "Waitrose.com is our fastest growing trading area across metrics including increased AOV, volume and total sales. Engage customers with relevant and highly visible content across web and app to raise awareness and drive sales for your brand.",
      buttonText: "Find out more",
      imageLink: '/assets/photography/2x20230831_BM_RetailMedia_Waitrose_5_20.jpg',
      downloadLink: "https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/Waitrose/ONSITE+MEDIA+-+WAITROSE+Insights+%26+Media+-+Media+Kit+2023+-+Last+updated+09_23.pdf", 
      points: [
        {
          pointTitle: "With onsite media you can:",
          text: "Reach in-market shoppers at any stage of their shopping journey to meet your brand objectives/nBoost brand awareness with highly visible placements on the homepage, and drive conversions with category pages – plus more.",
        },
        {
          pointTitle: "Key channel & audience stats ",
          text: "57m impressions online per month 2022/n£108 average basket spend, YTD 2022/nAverage 180k orders per week",
        },
        {
          pointTitle: "Media opportunities",
          text: "A wide range of display opportunities across key touchpoints and devices (Desktop, Mobile and APP) including WR Cellar/nSponsored products in Search, Browse and Check out",
        },
      ],
    },
    {
      title: "Offsite: Digital & Social",
      eventKey: "offsite",
      headline:
        "Extend the reach of your eComm campaigns by targeting shoppers away from Waitrose.com with our network of partner media across programmatic, social and affiliate channels.",
      buttonText: "Find out more",
      imageLink: '/assets/photography/2x20230831_BM_RetailMedia_Waitrose_4_1.jpg',
      downloadLink: 'https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/Waitrose/SOCIAL+%26+DIGITAL+-+WAITROSE+Insights+%26+Media+-+Media+Kit+2023+-+Last+updated+09_23.pdf',
      points: [
        {
          pointTitle: "With offsite media you can:",
          text: "Drive reach amongst hard to find customers across the web with a brand message or conversion call to action/nPair with online media to reinforce your brand message and maximise conversions/nEnhance your campaigns through a cross channel activation strategy.",
        },
        {
          pointTitle: "Key channel and audience stats",
          text: "Waitrose YouTube 266k vs Sainsburys 120k/nWaitrose Facebook 517k vs Ocado 348k/nWaitrose Instagram 588k vs Tesco 468k, Sainsbury's 308k and Ocado 80k",
        },
        {
          pointTitle: "Media opportunities",
          text: "Social/nPPC/nProgrammatic Display",
        },
      ],
    },
    {
      title: "Instore",
      eventKey: "instore",
      headline:
        "Influence and inspire customers during their shopping journey with high impact displays and sampling opportunities. Command attention – and drive customers to your brand in-store",
      buttonText: "Find out more",
      imageLink: '/assets/photography/2x20230831_BM_RetailMedia_Waitrose_2_239.jpg',
      downloadLink: 'https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/Waitrose/STORE+MEDIA+-+WAITROSE+Insights+%26+Media+-+Media+Kit+2023+-+Last+updated+09_23.pdf',
      points: [
        {
          pointTitle: "With in-store media you can:",
          text: "Boost brand engagement and buzz through direct to customer activity/nGenerate mass awareness and stand out in the in-store environment/nAmplify in store promotions and offers, raise awareness for new products, and drive purchase consideration at key seasonal moments.",
        },
        {
          pointTitle: "Key channel and audience stats",
          text: "329 stores nationwide/n945k daily footfall across our retail estate",
        },
        {
          pointTitle: "Media opportunities",
          text: "POS/nExperiential & Sampling/nScreens/nPrint",
        },
      ],
    },
    {
      title: "CRM & Loyalty",
      eventKey: "crm-loyalty",
      headline:
        "Leverage the power of Waitrose’s 1st party CRM data. Serve highly targeted offers and rewards to our highest spending customers.",
      buttonText: "Find out more",
      imageLink: '/assets/photography/2x20230831_BM_RetailMedia_Waitrose_4_41.jpg',
      downloadLink: 'https://jlp-media-insights--pdfs.s3.eu-west-2.amazonaws.com/Waitrose/CRM+%26+LOYALTY+-+WAITROSE+Insights+%26+Media+-+Media+Kit+2023+-+Last+updated+09_23.pdf',
      points: [
        {
          pointTitle: "With CRM & Loyalty you can:",
          text: "Boost brand engagement and buzz through direct to customer activity/nBuild trust and brand equity amongst WR’s most loyal shoppers/nDrive conversion with couponing and price promo",
        },
        {
          pointTitle: "Key channel and audience stats",
          text: "8.8m MyWaitrose members./n69% of all in store transactions are made with a MyWaitrose card.",
        },
        {
          pointTitle: "Media opportunities",
          text: "Coupon at Till/nCompetitions",
        },
      ],
    },
  ];
  const infographics = [
    {
      imgUrl:
        "/assets/infographics/JLP_Icon_RGB_Orange_Supplier Funded Incentives.svg",
      textHeadline: "Our audience is",
      text: "75% ABC1, vs 55% in wider grocery market",
    },
    {
      imgUrl: "/assets/infographics/JLP_Icon_RGB_Orange_Basket.svg",
      textHeadline: "Our customers spend",
      text: "27% higher per basket than the grocery retailer average",
    },
    {
      imgUrl: "/assets/infographics/JLP_Icon_RGB_Orange_Instore_Windows.svg",
      textHeadline: "We see ",
      text: "945k daily footfall across our estate",
    },
    {
      imgUrl: "/assets/infographics/JLP_Icon_RGB_Orange_Media.svg",
      textHeadline: "Our site receives",
      text: "432m impressions online per annum",
    },
  ];
  return (
    <div className="media-container">
      <Hero
        title="WAITROSE"
        fullWidth="true"
        titleColor="#ffffff"
        mobileCentered="true"
        overlayImgUrl="/assets/logos/waitrose_overlay.png"
        customClass="waitrose"
      />

      <Container className="w-100 main-container customers">
        <h3 className="text-md-center">
          Build connections that matter with Waitrose
        </h3>
        <p className="body-text body-l text-md-center mb-4">
          Reach your ideal customer through both our extensive physical and
          digital networks, as well as our loyal customer base.
        </p>
        <p className="body-text body-l text-md-center mb-4">
          A few facts and figures about Waitrose:
        </p>
        <Infographics infographics={infographics} />
      </Container>
      <div className="block">
        <Container className="w-100 main-container">
          <h3 className="text-md-center">The places you’ll want to be seen</h3>
          <p className="body-text body-l text-md-center">
            Using our portfolio of premium, high-visibility media you can
            activate across the entire customer journey. So you meet your brand
            objectives at every touchpoint.
          </p>
        </Container>
      </div>

      <div className="block-container">
        <div className="left-triangle"></div>
        <div className="right-triangle"></div>
        <div className="arrow"></div>
        <div className="block block-1">
          <Container>
            <Col xs={6}>
              <h3>Awareness</h3>
            </Col>
            <Col xs={6}>
              <ul>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_PPC.svg"
                    />
                  </span>
                  <p className="body-l">PPC</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Offsite_Media.svg"
                    />
                  </span>
                  <p className="body-l">Offsite programmatic display</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Social.svg"
                    />
                  </span>
                  <p className="body-l">Social</p>
                </li>
              </ul>
            </Col>
          </Container>
        </div>
        <div className="block block-2">
          <Container>
            <Col xs={6}>
              <h3>Consideration</h3>
            </Col>
            <Col xs={6}>
              <ul>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Coupons on App.svg"
                    />
                  </span>
                  <p className="body-l">Personalised offers</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Coupons at Till.svg"
                    />
                  </span>
                  <p className="body-l">Coupons at till</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Competitions.svg"
                    />
                  </span>
                  <p className="body-l">Competitions</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Magazines-recipe Cards.svg"
                    />
                  </span>
                  <p className="body-l">Magazine & recipe cards</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Direct Mail.svg"
                    />
                  </span>
                  <p className="body-l">Direct mail</p>
                </li>
              </ul>
            </Col>
          </Container>
        </div>
        <div className="block block-3">
          <Container>
            <Col xs={6}>
              <h3>Conversion</h3>
            </Col>
            <Col xs={6}>
              <ul>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Onsite_Media.svg"
                    />
                  </span>
                  <p className="body-l">Onsite media</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_In-store POS.svg"
                    />
                  </span>
                  <p className="body-l">In-store POS</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Experiential Showcase.svg"
                    />
                  </span>
                  <p className="body-l">Experiential showcase</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Events.svg"
                    />
                  </span>
                  <p className="body-l">Events</p>
                </li>
                <li>
                  <span>
                    <Image
                      height={85}
                      width={85}
                      src="/assets/icons/JLP_Icon_RGB_Green_Digital_Screens.svg"
                    />
                  </span>
                  <p className="body-l">Digital screens</p>
                </li>
              </ul>
            </Col>
          </Container>
        </div>
      </div>
      <div className="form-background">
        <Container className="w-100 main-container tab-container">
          <h3 className="text-md-center"> Opportunities too good to pass up</h3>
          <p className="body-text body-l mb-4 text-md-center">
            Take a closer look at our diverse range of prominent media
            opportunites to help you meet your objectives through the funnel.
          </p>
          <MediaTabsComponent tabs={tabs} />
        </Container>
      </div>
    </div>
  );
};

export default WaitrosePage;
