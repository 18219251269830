import { Form, Container } from "react-bootstrap";
import InputField from "../FormComponents/InputField";
import RadioButton from "../FormComponents/RadioButton";
import { useState } from "react";
import "./styles.scss";
import SubmitFormButton from "../Buttons/Forms/Submit";

const ContactForm = ({ title, formBackground }) => {
  const [state, setState] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
    partnered: "Yes",
    interestArea: "Waitrose",
    tandcs: false,
  });

  const handleChange = (key) => (value) => {
    if(key === 'tandcs') {
      value = value.target.checked;
    }
    setState({ ...state, [key]: value });
  };

  return (
    <div
      className={
        formBackground ? "form-background main-container" : "main-container"
      }
    >
      <Container
        className="small-container"
      >
        <div>
          {title && <h3>Get in touch</h3>}
          <Form>
            <div className="formGroup">
              <InputField
                label="Name"
                value={state.text}
                type="text"
                onChange={handleChange("name")}
              />
            </div>
            <div className="formGroup">
              <InputField
                label="Email"
                value={state.email}
                type="text"
                onChange={handleChange("email")}
              />
            </div>
            <div className="formGroup">
              <InputField
                label="Company"
                value={state.company}
                type="text"
                onChange={handleChange("company")}
              />
            </div>
            <div className="formGroup">
              <Form.Group>
                <Form.Label>Have we partnered together before?</Form.Label>
                <RadioButton
                  onChange={handleChange("partnered")}
                  isSelected={state.partnered === "Yes"}
                  label="Yes"
                  value="Yes"
                  id="partneredYes"
                  name="partnered"
                />
                <RadioButton
                  onChange={handleChange("partnered")}
                  isSelected={state.partnered === "No"}
                  label="No"
                  value="No"
                  name="partnered"
                  id="partneredNo"
                />
              </Form.Group>
            </div>
            <div className="formGroup">
              <Form.Group>
                <Form.Label>Area of interest</Form.Label>
                <RadioButton
                  onChange={handleChange("interestArea")}
                  isSelected={state.interestArea === "Waitrose"}
                  label="Waitrose"
                  value="Waitrose"
                  id="waitrose"
                  name="interest"
                />
                <RadioButton
                  onChange={handleChange("interestArea")}
                  isSelected={state.interestArea === "John Lewis"}
                  label="John Lewis"
                  value="John Lewis"
                  id="john_lewis"
                  name="interest"
                />
              </Form.Group>
            </div>
            <div className="formGroup">
              <InputField
                label="Message"
                value={state.message}
                type="textarea"
                onChange={handleChange("message")}
              />
            </div>
            <div className="formGroup">
              <Form.Check
                type="checkbox"
                value={state.tandcs}
                onChange={handleChange("tandcs")}
                label={
                  <span>
                    I agree with the following {" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.johnlewispartnership.co.uk/meta/privacy-policy.html"
                    >
                      JLP Privacy Policy
                    </a>
                  </span>
                }
              />
            </div>
            <SubmitFormButton
              formInfo={state}
              variant="primary"
              text="Send message"
            />
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default ContactForm;
